.c-tick-list {
	margin: 15px 0 0 0;
	text-align: left;

	.o-scoped & {
		margin: 20px 0;
	}

	li {
		@include font-size($font-size-6, $font-leading-6);
		background: url(../images/svg/sprites/tick-green.svg) 0 3px no-repeat;
		background-size: 18px;
		padding-left: 30px;
		margin: 8px 0 0 0;

		.o-scoped & {
			@include font-size($font-size-7, $font-leading-7);
			padding-left: 25px;
			margin: 15px 0;
			background-size: 15px;
			&:before {
				display: none;
			}
		}
	}

	&--reverse {
		li {
			background: url(../images/svg/sprites/tick.svg) 0 3px no-repeat;
			background-size: 18px;
			padding-left: 30px;
		}
	}
}