.o-scoped {
	h2, h3, h4, h5 {
		color: #666;
	}
	p, ul, figure, img {
		+ h2,
		+ h3,
		+ h4,
		+ h5, {
			margin-top: 30px;
		}
	}

	ul {
		li {
			position: relative;
			padding-left: 10px;
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				content: "•";
				color: palette(blue);
				padding-right: 5px;
				position: absolute;
				left: 0;
			}
		}
	}
	
	img {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	figure {
		margin: 1em 0;
		text-align: center;
		figcaption {
			font-size: 14px;
		}
		img {
			margin-bottom: .5em;
		}
	}
	
}