*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: 1px dotted #f1f1f1;
}

// Text highlight
::selection {
	color: $text-highlight-color;
	background: $text-highlight-bg-color;
}

::-moz-selection {
	color: $text-highlight-color;
	background: $text-highlight-bg-color;
}

html,
body {
	-moz-osx-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	-webkit-font-smoothing: antialiased !important;
	color: $c-font;
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	min-width: 320px;
	position: relative;
	text-rendering: optimizeLegibility !important;
	width: 100%;
	z-index: 0;
}

body {
	font-size: toRems($base-font-size);
}

// Links
a[href*="mailto:"] {
	word-wrap: break-word;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}

// Reset Lists
ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Images
figure {
	margin: 0;
}

img {
	//display: block;
	height: auto;
	max-width: 100%;
}

a {
	text-decoration: none;
	color: palette(blue);
	&:hover {
		color: palette(green);
	}
}


// Default Transitions
a,
input[type="text"],
textarea,
button,
[class^="c-btn"] {
	-webkit-tap-highlight-color: $tap-highlight-color;
	transition-duration: 300ms;
	transition-property: background-color, color, opacity, border, border-color, background-position, outline, box-shadow, border-bottom, text-decoration, left, top, transform;
	transition-timing-function: $global-easing;
	transform-origin: center center;
}

body:after {
	@include transition(all $global-easing-time $global-easing);
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background: rgba(0,0,0,0);
} 
body.js-megemenu-active:after {
	background: rgba(0,0,0,.4);
	z-index: 1;
}
