// Site
$max-site-width: 1200px;

// Font families
$font-name: 'Work Sans', sans-serif;

// Font sizes
$font-size-1: 44px;
$font-size-2: 36px;
$font-size-3: 30px;
$font-size-4: 26px;
$font-size-5: 21px;
$font-size-6: 18px;
$font-size-7: 16px;

// Small font sizes
$font-small: 14px;
$font-xsmall: 13px;
$font-xxsmall: 12px;

// Font leading
$font-leading-1: 58px;
$font-leading-2: 44px;
$font-leading-3: 40px;
$font-leading-4: 36px;
$font-leading-5: 30px;
$font-leading-6: 26px;
$font-leading-7: 21px;

// Font weights
$font-weight-thin: 100;
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

// Font letter spacing
$letter-spacing-small: 0.025em;
$letter-spacing-medium: 0.05em;
$letter-spacing-large: 0.1em;
$letter-spacing-xlarge: 0.2em;

// Base Variables
$base-font-family: $font-name, arial;
$base-font-size: 16px;
$base-font-weight: 400;
$base-line-height: 1.6;

// Neat grid settings
$grid-columns: 12;
$max-width: $max-site-width;
$visual-grid: false !default;
$border-box-sizing: true !default;
$disable-warnings: false !default;

// Breakpoints
// Extra large devices (large desktops)
$bp-xlarge: min-width em(1198);
// Large devices (desktops)
$bp-large: max-width em(1198);
// Medium devices (tablets)
$bp-medium: max-width em(990);
// Small devices (landscape phones)
$bp-small: max-width em(766);
// Extra small devices (portrait phones)
$bp-xsmall: max-width em(542);

// Z-indexes
$z-header: 1000;
$z-modal: 2000;

// Global variables
$global-radius: 4px;
$global-easing: cubic-bezier(0.165, 0.84, 0.44, 1);
$global-easing-time: .3s;

// Global spacing
$spacing-xxsmall: 5px;
$spacing-xsmall: 10px;
$spacing-small: 15px;
$spacing-base: 20px;
$spacing-medium: 30px;
$spacing-large: 50px;
$spacing-xlarge: 100px;

$menu_width: 220px;
