$transition: .3s ease-out;

.o-section--downloads {
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	background-color: #F1F1F1;
}

.c-download {
	display: flex;
	margin-right: -70px;

	@media (max-width: 1329px)  {
		margin-right: -15px;
	}

	@include media($bp-medium) {
		flex-wrap: wrap;
		margin-right: 0;
	}

	&__group {
		flex-basis: 50%;
		padding-top: 80px;
		padding-bottom: 70px;
		transition: flex $transition;
		
		&--cons {
			padding-right: 60px;

			@include media($bp-large) {
				padding-right: 30px;
			}

			@include media($bp-small) {
				flex-basis: 100%;
				padding-right: 0;
			}
		}

		&--pros {
			padding-left: 60px;
			padding-right: 60px;
			margin-right: -60px;
			box-shadow: 0 0 24px rgba(0,0,0,.1);

			@include media($bp-large) {
				padding-left: 30px;
				padding-right: 30px;
				margin-right: -30px;
			}

			@include media($bp-medium) {
				margin-right: -30px;
    			flex-basis: calc(50% + 30px);
			}

			@include media($bp-small) {
				flex-basis: calc(100% + 60px);
				margin-left: -30px;
				margin-right: -30px;
			}
		}

		&--download {
			flex: 0 0 70px;
			margin-left: 60px;
			background-color: palette(green);
			position: relative;
			box-shadow: 0 0 24px rgba(0,0,0,.1);

			@include media($bp-large) {
				margin-left: 30px;
			}

			@include media($bp-medium) {
				flex: 0 0 100%;
				margin-left: 0;
				box-shadow: none;
				margin-left: -30px;
				margin-right: -30px;
				flex-basis: calc(100% + 60px);
			}

			&.open {
				flex: 0 0 33.333%;

				@include media($bp-medium) {
					flex-basis: calc(100% + 60px);
				}
			}
		}

		h2 {
			max-width: 320px;
			line-height: 2.5rem;
		}
	}

	&__list {
		li {
			padding-left: 47px;
			margin-top: 20px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				position: absolute;
				left: 3px;
				top: 3px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='32' width='32'%3E%3Cpath d='M.008475 5.343705l5.33523-5.33523 10.67046 10.67046L26.684627.008475l5.335231 5.33523-10.670463 10.67046 10.670463 10.670462-5.335231 5.335231-10.670462-10.670463-10.67046 10.670463-5.33523-5.335231 10.67046-10.670462z' fill='%230082cd'/%3E%3C/svg%3E");
				background-position: center top;
				background-size: contain;
				background-repeat: no-repeat;

				.c-download__group--pros & {
					left: 0;
					top: 0;
					width: 26px;
					height: 26px;
					width: 30px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='32' width='32'%3E%3Cpath d='M.029276 13.943291l4.262062-4.262062 8.524124 8.524125L27.732681 3.288136l4.262062 4.262062-19.179281 19.179281z' fill='%2379c358'/%3E%3C/svg%3E");
				}
			}
		}
	}

	&__content {
		padding-left: 70px;
		width: 0;
		overflow: hidden;
		position: relative;
		height: 100%;
		transition: width $transition;

		@include media($bp-medium) {
			width: 100%;
		}

		.c-download__group.open & {
			width: 100%;
		}

		&-inner {
			position: absolute;
			max-width: 233px;
			color: white;
			padding-right: 20px;
			box-sizing: content-box;

			@include media($bp-medium) {
				max-width: 100%;
				padding-right: 30px;
				position: relative;
			}

			h3 {
				color: white;
				font-weight: 400;
				line-height: 1.8rem;
				margin-bottom: 25px;
				opacity: 0;
				transform: translateY(20px);
				transition: transform $transition .4s, opacity $transition;

				.c-download__group.open & {
					opacity: 1;
					transform: none;
					transition: transform $transition .4s, opacity $transition .4s;
				}

				@include media($bp-medium) {
					opacity: 1;
					transform: none;
				}
			}

			p {
				opacity: 0;
				transform: translateY(20px);
				transition: transform $transition .4s, opacity $transition;
				margin-top: 20px;
				margin-bottom: 20px;

				.c-download__group.open & {
					opacity: 1;
					transform: none;
					transition: transform $transition 1s, opacity $transition 1s;
				}

				@include media($bp-medium) {
					opacity: 1;
					transform: none;
				}
			}

			.c-btn {
				opacity: 0;
				transform: translateY(20px);
				transition: background-color .3s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1), transform $transition .4s, opacity $transition;

				.c-download__group.open & {
					opacity: 1;
					transform: none;
					transition: background-color .3s cubic-bezier(0.165, 0.84, 0.44, 1), color .3s cubic-bezier(0.165, 0.84, 0.44, 1), transform $transition 1.3s, opacity $transition 1.3s;
				}

				@include media($bp-medium) {
					opacity: 1;
					transform: none;
				}
			}
		}
	}

	h4 {
		position: absolute;
		left: 50px;
		transform-origin: bottom left;
		transform: rotate(-90deg) translateX(-80px);
		z-index: 1;

		a {
			color: #000;
			font-weight: 400;
			outline: 0;
		}
	}

	&__close {
		position: absolute;
		z-index: 1;
		color: transparent;
		width: 20px;
		height: 20px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='32' width='32'%3E%3Cpath d='M.008475 5.343705l5.33523-5.33523 10.67046 10.67046L26.684627.008475l5.335231 5.33523-10.670463 10.67046 10.670463 10.670462-5.335231 5.335231-10.670462-10.670463-10.67046 10.670463-5.33523-5.335231 10.67046-10.670462z' fill='%23ffffff'/%3E%3C/svg%3E");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		top: 20px;
		right: 25px;
		transform: rotate(-45deg);
		outline: 0;

		@include media($bp-medium) {
			display: none;
		}

		&:active,
		&:hover {
			color: transparent;
			outline: 0;
		}

		.c-download__group.open & {
			transform: rotate(0deg);
		}
	}

	&__image {
		display: block;
		width: 233px;
		max-height: 328px;
		max-width: 100%;
		height: auto;
		opacity: 0;
		transform: translateY(20px);
		transition: transform $transition .4s, opacity $transition;

		@include media($bp-medium) {
			opacity: 1;
			transform: none;
		}

		.c-download__group.open & {
			opacity: 1;
			transform: none;
			transition: transform $transition .7s, opacity $transition .7s;
		}
	}
}
