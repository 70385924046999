.c-tile {
	background: palette(blue);
	padding: 0 20px;
	margin-bottom: 40px;

	h2 {
		@include font-size($font-size-3, $font-leading-3);
		color: $c-white;
		margin-top: 20px;
		
		@include media($bp-small) {
			@include font-size($font-size-4, $font-leading-4);
		}

		@include media($bp-xsmall) {
			@include font-size($font-size-5, $font-leading-5);
		}
	}

	p {
		color: $c-white;
	}
}