.c-link {
	color: palette(blue);
	font-weight: 700;
	letter-spacing: .1em;
	&:hover {
		color: palette(green);
	}

	&--back {
		display: inline-block;
		margin-top: 40px;

		&:hover {
			svg {
				fill: palette(green);
			}
		}

		svg {
			@include transition(fill .2s linear);
			fill: palette(blue);
			display: inline;
			width: 25px;
			height: 25px;
			position: relative;
			top: 5px;
		}
	}
}