.c-form {
	text-align: left;

	&__field-row {
		@include clearfix;
	}

	&__field {
		padding: .75em 0;
		
		&.parsley-error {
			.c-checkbox + label:before {
					border-color: red;
			}
		}
	}

	&__field-clear {
		clear: both;
	}

	.c-form__field.required {
		label:after {
			content: "*";
			color: red;
			font-style: normal;
			font-size: 12px;
			position: absolute;
			top: 0px;
			padding-left: 3px;
		}
	}

	input[type="text"],
	input[type="date"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="tel"],
	textarea  {
		border: 1px solid palette(border); 
		display: block;
	}

	input[type="submit"] {
		border: 0;
	}

	label {
		@include font-size($font-size-7, $font-leading-7);
		color: $c-black;
		display: block;
		margin-bottom: 5px;
		position: relative;

		em {
			color: red;
			font-style: normal;
			font-size: 12px;
			position: absolute;
			top: 0px;
			padding-left: 3px;
		}
	}

	.parsley-error {
		border-color: red;
	}
}

.c-form--reverse {
	background: palette(blue);
	padding: 10px 30px;

	label {
		color: $c-white;
	}

	h2 {
		color: $c-white;
		margin-top: 15px;
	}

	p {
		color: $c-white;
	}
}


.parsley-errors-list {
	color: red;
}