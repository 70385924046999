form {

	fieldset {
		border: none;
		padding: 0;
	}

	legend {

	}

	ol {
		li {
			margin-bottom: $spacing-base;
		}
	}


}

button {
	background: none;
	border: none;
	padding: 0;
}

label {
	display: block;
	font-size: toRems($font-xxsmall);
	font-weight: 500;
	margin-bottom: $spacing-xsmall - 1px;
	padding-left: 2px;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
textarea {
	-webkit-appearance: none;
	appearance: none;
	background: $c-white;
	border: 2px solid $c-white;
	color: $c-font;
	font-size: toRems($font-small);
	padding: $spacing-xsmall $spacing-small;
	width: 100%;

	&::-webkit-input-placeholder {
		color: $c-font;
	}

	&:-moz-placeholder {
		color: $c-font;
	}

	&::-moz-placeholder {
		color: $c-font;
	}

	&:-ms-input-placeholder {
		color: $c-font;
	}

	.parsley-error & {
		border-color: palette(errors) !important;
	}

	&:focus {
		outline: none;
	}
}

textarea {
	-webkit-appearance: none;
	resize: none;
	min-height: 150px;

	&.parsley-error {
		&::-webkit-input-placeholder {
			color: palette(errors);
		}

		&:-moz-placeholder {
			color: palette(errors);
		}

		&::-moz-placeholder {
			color: palette(errors);
		}

		&:-ms-input-placeholder {
			color: palette(errors);
		}
	}

	&:focus {
		outline: none;
	}
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
textarea {
	-webkit-appearance: none;

	&.parsley-error {
		&::-webkit-input-placeholder {
			color: palette(errors);
		}

		&:-moz-placeholder {
			color: palette(errors);
		}

		&::-moz-placeholder {
			color: palette(errors);
		}

		&:-ms-input-placeholder {
			color: palette(errors);
		}
	}

	&:focus {
		outline: none;
	}
}

// overrides the webkit appearance none set globally so that embedded forms can display default checkbox inputs
.ao-form input[type="checkbox"] {
	appearance: checkbox;
	-webkit-appearance: checkbox;
}