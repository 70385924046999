.c-btn,
.submit input {
	display: inline-block;
	background: palette(green);
	color: $c-white;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: .1em;
	padding: 8px 16px;
	border-radius: 50px;

	&:hover {
		background: palette(blue);
		color: $c-white;
	}

	&--large {
		font-size: rem(21);
		padding: 12px 24px;
	}

	&--outline {
		background: none;
		border: 3px solid palette(green);
		color:  palette(green);
		padding: 8px 30px;

		&:hover {
			background: palette(green);
			color: $c-white;
		}
	}

	&--outline-rev {
		background: none;
		border: 3px solid $c-white;
		padding: 8px 30px;

		&:hover {
			background: $c-white;
			color: palette(blue);
		}
	}

	&--full-width {
		width: 100%;
	}
	
	&--blue {
		background: palette(blue);

		&:hover {
			background: $c-white;
			color: palette(blue);
		}
	}

	&--wide {
		padding: 8px 40px;
	}
}