.c-quote-featured {
	@include clearfix;
	position: relative;
	
	&__content {
		@include span-columns(12);
		background: url(../images/svg/sprites/quote-circle-left.svg) 0 20px no-repeat;
		background-size: 40px 40px;
		padding-left: 50px;
		padding-bottom: 20px;

		@include media($bp-small) {
			@include span-columns(12);
		}

		svg {
			width: 15px;
			height: 15px;
			fill: palette(green);
		}
	}
	// &__img {
	// 	@include span-columns(3);
	// 	height: 100%;
	// 	position: absolute;
	// 	right: 8.5298%;
	// 	bottom: -5px;
	// 	display: table;

	// 	@include media($bp-small) {
	// 		@include span-columns(12);
	// 		position: relative;
	// 		right: auto;
	// 		text-align: center;
		
	// 	}

	// }
	&__txt {
		@include font-size($font-size-4, $font-leading-4);
		padding: 0;
		margin: 0;

		@include media($bp-medium) {
			@include font-size($font-size-5, $font-leading-5);
		}
	}
	&__person {
		color: $c-black;
		font-weight: 700;
		display: block;
	}
	&__role {
		font-size: rem(13);
	}
}

.c-quote {
	@include span-columns(4);
	@include dotted-border(100%, 100%, repeat-y);
	padding: 20px 2.35765% 20px 0px;

	@include media($bp-small) {
		@include span-columns(12);
		@include dotted-border(0, 100%, repeat-x);
		&:last-of-type {
			padding-bottom: 0;
		}
	}

	&:last-of-type {
		background: none;
	}

	&__txt {
		font-size: rem(16);
		padding: 0;
		margin: 0;

		svg {
			width: 15px;
			height: 15px;
			fill: palette(green);
		}
	}

	&__person {
		color: $c-black;
		font-weight: 700;
		display: block;
		padding-left: 100px;
	}

	&__role {
		padding-left: 100px;
		display: block;
		font-size: rem(13);
	}

	&__img {
		float: left;
	}
}

.c-quote-sidebar {
	background: palette(grey, light);
	color: $c-black;
	padding: 20px;
	margin-bottom: 50px;
	text-align: center;

	&__txt {
		font-size: rem(18);
		padding: 0;
		margin: 0;

		p {
			margin-top: 5px;
		}
	}


	&__person {
		color: $c-black;
		font-weight: 700;
		display: block;
	}

	&__role {
		display: block;
		font-size: rem(13);
	}

	img {
		margin: 0;
	}
}