/**
 * Video Modal
 */

.video-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.video-modal__shade {
	background-color: #000000;
	opacity: 0.7;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.video-modal__container {
	position: relative;
	z-index: 10;
	max-width: calc(100% - 44px);
	width: 764px;
	padding-top: 25px;

	@include media($bp-medium) {
		@media (orientation: landscape) {
			padding-top: 0;
			width: auto;
			max-width: 100%;
		}
	}
}

.video-modal__caption {
	color: #ffffff;
	margin-top: 10px;

	@include media($bp-medium) {
		@media (orientation: landscape) {
			display: none;
		}
	}
}

.video-modal__close {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	right: -25px;
	background-image: url(../img/close.svg);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	@include media($bp-medium) {
		right: 0;

		@media (orientation: landscape) {
			right: -25px;
			top: 10px;
		}
	}
}

.modal__video-outer {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;

	@include media($bp-medium) {
		@media (orientation: landscape) {
			padding-bottom: 0;
			width: 178vh;
			max-width: 100%;
			height: 100vh;
			margin-left: auto;
			margin-right: auto;
		}
	}

	& iframe,
	& object,
	& embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}