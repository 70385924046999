.c-clients {
	text-align: center;
	@include dotted-border(0, 100%, repeat-x) 
	
	padding-bottom: 20px;
	margin-bottom: 40px;


	h2 { 
		font-weight: 400;
		font-size: rem(24);
		margin-bottom: 20px;
	}

	.c-slick  {
		padding: 0 80px;

		img {
			margin: 0 auto;
		}
	}

	&--internal {
		background: none;
		margin-bottom: 0;
		margin-top: 40px;
	}
}