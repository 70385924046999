.c-branding {
	@include transition(all .2s linear);
	@include span-columns(3);
	margin-top: 20px;

	svg {
		@include transition(all .2s linear);
		fill: $c-white;

		&:hover {
			fill: palette(green);
		}
	}

	@include media($bp-medium) {
		transition: all .2s cubic-bezier(.16, .68, .43, .99);
		width: 190px;

		.pushy-open-left & {
			 margin-left: $menu_width;
		}
	}

	@include media($bp-xsmall) {
		.pushy-open-left & {
			 margin-left: 0;
		}
	}

	.stuck & {
		@include span-columns(2.2);
		margin-top: 11px;

		@include media($bp-medium) {
			width: 140px;
		}
	}
}