.c-hero {
	background: $c-black url(../images/hero-home.jpg) center center no-repeat;
	background-size: cover;
	color: $c-white;
	text-align: center;
	padding: 160px 0 10px 0;
	margin-top: -142px;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		background: rgba(0,0,0,0.6);
		background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.6)), color-stop(50%, rgba(0,0,0,0.25)), color-stop(51%, rgba(0,0,0,0.26)), color-stop(100%, rgba(0,0,0,0.6)));
		background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
		background: -o-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
		background: -ms-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
		background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
	}

	.o-grid {
		position: relative;
		z-index: 2;
	}

	&__headline {
		color: $c-white;
		padding: 0 20%;

		@include media($bp-medium) {
			padding: 0;
		}
	}

	&__txt {
		@include font-size($font-size-6, $font-leading-6);
		margin-bottom: 60px;
		padding: 0 18%;

		@include media($bp-medium) {
			padding: 0;
		}

		@include media($bp-small) {
			@include font-size($font-size-7, $font-leading-7);
		}
	}

	&__more {
		display: inline-block;
		color: $c-white;
		margin-top: 15px;
		outline: 0;

		&:hover {
			color: palette(green);

			svg {
				fill: palette(green);
			}
		}

		svg {
			@include transition(all $global-easing-time $global-easing);
			display: block;
			margin: 0 auto;
			margin-top: 10px;
			width: 40px;
			height: 40px;
			fill: $c-white;
		}
	}

}