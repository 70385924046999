.c-gated {
	margin-top: 55px;

	@include breakpoint($bp-small) {
		margin-top: 25px;
	}

	h1 {
		+ h2 {
			margin-top: .5em;
		}
	}
}