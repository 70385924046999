.c-team {
	&__member {
		@include span-columns(3);
		margin-bottom: 20px;
		margin-top: 20px;

		@include media($bp-small) {
			@include span-columns(12);
		}

		&:nth-child(4) {
			clear: both;
		}

		&:nth-child(4n+3) {
			@include omega;
		}

		&--primary {
			@include span-columns(4);

			&:nth-child(4n) {
				clear: none;
			}

			&:nth-of-type(3n) {
				@include omega;
			}

			&:nth-of-type(3n+1) {
				clear: left;
			}

			@include media($bp-small) {
				@include span-columns(12);
			}
		}
	}

	img {
		width: 80%;
		margin: 0 auto;
	}

	h3 {
		@include font-size($font-size-4, $font-leading-4);
		font-weight: 400;
		margin-top: 10px;
	}
	h4 {
		font-weight: 400;
	}


}