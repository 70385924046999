.c-post-list {
	
	//margin: 0 -1.178825%;

	.c-post {
		@include span-columns(3);

		@include media($bp-small) {
			@include span-columns(12);
		}
	}

	&__initial {
			.c-post {
				&:first-of-type {
				@include span-columns(6)

				@include media($bp-small) {
					@include span-columns(12);
				}
			}
			
			&:nth-child(5n+10) {
				@include span-columns(6)
				@include omega;

				@include media($bp-small) {
					@include span-columns(12);
				}
			}

			&:nth-child(3) {
				@include omega;

				@include media($bp-small) {
					@include span-columns(12);
				}
			}

			&:nth-child(10n+7) {
				@include omega;

				@include media($bp-small) {
					@include span-columns(12);
				}
			}

			&:nth-child(7n+7) {
				@include omega;
				
				@include media($bp-small) {
					@include span-columns(12);
				}
			}
		}
	}

	&__more {
		.c-post {
			&:nth-child(4n) {
				@include omega;
			}
			&:nth-child(4n+1) {
				clear: both;
			}
		}
	}

	// .u-row {
	// 	.c-post:last-of-type {
	// 		@include omega;
	// 	}
	// }

	// .u-row-1 {
	// 	.c-post:first-child {
	// 		@include span-columns(6)
	// 	}
	// }

}