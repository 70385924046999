.c-icon-list {
	text-align: left;

	li {
		position: relative;
		padding: 30px 0 30px 100px;

		@include media($bp-medium) {
			height: auto !important;
		}

		@include media($bp-xsmall) {
			padding: 20px 0 20px 70px;
		}
	}

	img {
		@include transition(all .5s $global-easing);
		@include transform(scale(0));
		position: absolute;
		top: 15px;
		left: 0;
		opacity: 0;

		-webkit-backface-visibility: hidden;

		@include media($bp-xsmall) {
			width: 50px;
			height: 50px;
		}
	}
	.is-visible img {
		@include transform(scale(1));
		opacity: 1;
	}
	h3 {
		@include font-size($font-size-5, $font-leading-5);
		font-weight: 400;
		color: $c-black;
		margin: 0;
	}

	p {
		margin: .5em 0;
	}
}