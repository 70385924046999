/**
 * Generated SVG Sprite Sizes
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more icons to the svg spritesheets:
 *    1. Add svgs to "images/svg/sprites/"
 *    2. Run `gulp svg-sprites` in your terminal
 *
 */

%svg-icon {
	display: inline-block;
}

.svg-arrow-down {
	@extend %svg-icon;
	height: 34.99px;
	width: 34.99px;
}

.svg-arrow-down-mini {
	@extend %svg-icon;
	height: 34.04px;
	width: 59.72px;
}

.svg-arrow-down-small {
	@extend %svg-icon;
	height: 199.3px;
	width: 349.7px;
}

.svg-arrow-left {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-arrow-right {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-arrow-up {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-calendar {
	@extend %svg-icon;
	height: 25.59px;
	width: 25.6px;
}

.svg-call {
	@extend %svg-icon;
	height: 19.94px;
	width: 20.33px;
}

.svg-envelope {
	@extend %svg-icon;
	height: 20px;
	width: 26.89px;
}

.svg-facebook {
	@extend %svg-icon;
	height: 34.02px;
	width: 34.02px;
}

.svg-google {
	@extend %svg-icon;
	height: 34.02px;
	width: 34.02px;
}

.svg-linkedin {
	@extend %svg-icon;
	height: 34.02px;
	width: 34.02px;
}

.svg-phone {
	@extend %svg-icon;
	height: 45.18px;
	width: 45.28px;
}

.svg-quote-circle-left {
	@extend %svg-icon;
	height: 54px;
	width: 54px;
}

.svg-quote-left {
	@extend %svg-icon;
	height: 19.2px;
	width: 25.94px;
}

.svg-quote-right {
	@extend %svg-icon;
	height: 19.2px;
	width: 25.94px;
}

.svg-smartphone {
	@extend %svg-icon;
	height: 20px;
	width: 12px;
}

.svg-twitter {
	@extend %svg-icon;
	height: 34.02px;
	width: 34.02px;
}

