.u-block.u-block {
   display: block;
}

.u-inline-block.u-inline-block {
    display: inline-block;
}

/**
 * Displays @bp-medium
 */

.u-block\@medium.u-block\@medium {
 display: block;
}

.u-inline-block\@medium.u-inline-block\@medium {
    display: inline-block;
}

/**
 * Floats @bp-medium
 */

.u-float-left\@medium.u-float-left\@medium {
    @include breakpoint($bp-medium) {
        float: left !important;
    }
}

.u-float-right\@medium.u-float-right\@medium {
    @include breakpoint($bp-medium) {
        float: right !important;
    }
}

.u-clearfix.u-clearfix {
    @include clearfix;
}

.u-table {
    display: table;
    width: 100%;
}
.u-table-cell {
    display: table-cell;
    vertical-align: middle;
}
