.u-float-right {
	float: right;
	margin-left: 20px;
	margin-right: 20px;

	@include breakpoint($bp-small) {
		float: none;
		margin-left: 0px;
		margin-right: 0px;
	}
}

.u-clear {
	clear: both;
}