.c-telephone {
	@include font-size($font-size-3, $font-leading-3);
	color: $c-white;
	float: right;
	font-weight: 600;
	margin: 10px 0;

	@include media($bp-medium) {
		@include font-size($font-size-5, $font-leading-5);
		margin: 0px 0;
	}

	@include media($bp-small) {
		span {
			display: none;
		}
	}

	svg {
		position: relative;
		top: 3px;
		fill: palette(grey);

		@include media($bp-medium) {
			width: 20px;
			top: 8px;
		}

		@include media($bp-small) {
			fill: $c-white;
			width: 25px;
		}
	}
}