.c-header {
	@include transition(all .5s $global-easing);
	width: 100%;
	background: rgba(palette(grey, x-dark), 0);
	position: relative;
	z-index: 10;
	
	&.stuck {
		background: palette(grey, x-dark);
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
	}

}