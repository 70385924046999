.c-industry {
	@include span-columns(4);
	text-align: center;

	@include media($bp-small) {
		@include span-columns(12);
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__header {		
		position: relative;
		img {
			width: 100%;
			max-height: 100%;
			display: block;
		}
	} 

	&__title {
		@include transition(all 2s $global-easing);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($c-black, .7);

		.is-visible & {
			background: rgba($c-black, 0);
		}

		h3 {
			@include transform(translate3d(0,0,0));
			@include transition(all 2s $global-easing);
			@include font-size($font-size-4, $font-leading-4);
			color: $c-white;
			display: table;
			vertical-align: middle;
			width: 100%;
			height: 100%;

			opacity: 0;
			padding: 30px;

			background: rgba(0,0,0,0.6);
			background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.6)), color-stop(50%, rgba(0,0,0,0.25)), color-stop(51%, rgba(0,0,0,0.26)), color-stop(100%, rgba(0,0,0,0.6)));
			background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -o-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -ms-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );

			.is-visible & {
				opacity: 1;
			}



			span {
				display: table-cell;
				vertical-align: middle;
				width: 100%;
				height: 100%;
			}
		}

	}
}