.u-spacing-top-small.u-spacing-top-small {
	margin-top: $spacing-small;
}

.u-spacing-bottom-small.u-spacing-bottom-small {
	margin-top: $spacing-small;
}

.u-spacing-top-base.u-spacing-top-base {
	margin-top: $spacing-base;
}

.u-spacing-bottom-base.u-spacing-bottom-base {
	margin-top: $spacing-base;
}

.u-spacing-top-medium.u-spacing-top-medium {
	margin-top: $spacing-medium;
}

.u-spacing-bottom-medium.u-spacing-bottom-medium {
	margin-top: $spacing-medium;
}

/**
 * @bp-medium
 */

.u-spacing-top-small\@medium.u-spacing-top-small\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-small;
    }
}

.u-spacing-bottom-small\@medium.u-spacing-bottom-small\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-small;
    }
}

.u-spacing-top-base\@medium.u-spacing-top-base\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-base;
    }
}

.u-spacing-bottom-base\@medium.u-spacing-bottom-base\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-base;
    }
}

.u-spacing-top-medium\@medium.u-spacing-top-medium\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-medium;
    }
}

.u-spacing-bottom-medium\@medium.u-spacing-bottom-medium\@medium {
	@include breakpoint($bp-medium) {
        margin-top: $spacing-medium;
    }
}

.u-pad-top {
	padding-top: 100px;
}
