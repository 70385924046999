// Colour palletes
$palettes: (
    color: (
        x-dark: #444,
        dark: #444,
        mid-dark: #444,
        base: #444,
        mid-light: #444,
        light: #444,
        x-light: #444,
		xx-light: #444
    ),
    errors: (
        base: #f00f00
    ),
    border: (
        base: #d6d8da
    ),
    red: (
        base: #e04539
    ),
    blue: (
        base: #1a85c7
    ),
    green: (
        base: #79c358
    ),
    grey: (
        base: #959595,
        light: #f4f4f4,
        dark: #323137,
        x-dark: #28272c
    )
);

// Global colors
$c-white: #ffffff;
$c-black: #000000;
$c-link: #754ACC;
$c-link-hover: palette(color, light);
$c-link-visited: palette(color, light);
$c-font: #838383;
$c-placeholder: palette(color);
$c-border: palette(color);

// Text highlight colors
$text-highlight-color: $c-white;
$text-highlight-bg-color: palette(color);
$tap-highlight-color: rgba(255,255,255, 0);

// Social colors
$c-facebook: #3b5998;
$c-google-plus: #dd4b39;
$c-instagram: #125688;
$c-linked-in: #007bb5;
$c-pinterest: #cb2027;
$c-twitter: #00aced;
$c-youtube: #bb0000;
