.c-integrations {
	&__item {
		@include span-columns(3);
		border: 2px solid $c-white;
		padding: 15px;
		margin-top: 12px;
		margin-bottom: 12px;
		&:nth-child(4n) {
			@include omega;
		}

		@include media($bp-medium) {
			@include span-columns(6);
			&:nth-child(2n) {
				@include omega;
			}
		}

		@include media($bp-small) {
			@include span-columns(12);
		}
	}
	&__title {
		@include font-size($font-size-5, $font-leading-5);
		font-weight: 400;
		margin: 0 0 5px 0;
	}
	p {
		margin: 0;
	}
}