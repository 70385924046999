.c-social {
	@extend %reset-list;
	li {
		display: inline-block;
		margin: 10px 5px 0 0;

		.c-footer--mini & {
			margin: 0 5px 10px 0;
		}

		@include media($bp-medium) {
			//display: block;
		}
	}

	a {
		&:hover {
			opacity: .7;
		}
	}

	.c-footer--mini & {
		float: right;
	}
}