.c-select {
	background-color: #f1f1f1;
	border: 1px solid #eee;
	color: $c-font;
	cursor: pointer;
	display: block;
	font-size: toEms(14px);
	margin: 0;
	padding: 0;
	position: relative;

	&.parsley-error {
		border-color: palette(errors);
	}

	&:before {
		@extend %i-chevron-down;
		color: palette(color, x-light);
		display: block;
		font-size: toRems(11px);
		line-height: 1;
		position: absolute;
		right: $spacing-small;
		top: 50%;
		transform: translateY(-50%);

		.lt-ie10 & {
			display: none;
		}
	}

	select {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		background-image: none;
		border: none;
		box-shadow: none;
		color: $c-font;
		cursor: pointer;
		font-size: toRems($font-small);
		line-height: normal;
		line-height: 1;
		padding: 16px $spacing-small;
		position: relative;
		width: 100%;

		.lt-ie10 & {
			padding: 11px;
		}
	}
}
