.c-banner {
	background: url(../images/hero-fallback.jpg) center center no-repeat;
	background-size: cover;
	margin-top: 55px;
	min-height: 360px;
	text-align: center;
	color: $c-white;
	padding: 100px 0;
	position: relative;

	&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			background: rgba(0,0,0,0.6);
			background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.6)), color-stop(50%, rgba(0,0,0,0.25)), color-stop(51%, rgba(0,0,0,0.26)), color-stop(100%, rgba(0,0,0,0.6)));
			background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -o-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -ms-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
		}

	@include media($bp-medium) {
		margin-top: 40px;
		min-height: 280px;
	}

	@include media($bp-small) {
		min-height: 200px;
	}

	&--text {
		background: url(../images/hero-fallback.jpg) center center no-repeat;
		background-size: cover;

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			background: rgba(0,0,0,0.6);
			background: -moz-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.6)), color-stop(50%, rgba(0,0,0,0.25)), color-stop(51%, rgba(0,0,0,0.26)), color-stop(100%, rgba(0,0,0,0.6)));
			background: -webkit-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -o-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: -ms-linear-gradient(left, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.26) 51%, rgba(0,0,0,0.6) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
		}
	}

	&--map {
		background: palette(grey, light);
		&:after {
			display: none;
		}
	}

	&__headline {
		color: $c-white;
		padding: 0 20%;
		position: relative;
		z-index: 10;

		@include media($bp-medium) {
			padding: 0;
		}
	}

	&__txt {
		@include font-size($font-size-6, $font-leading-6);
		padding: 0 20%;
		position: relative;
		z-index: 10;

		@include media($bp-medium) {
			padding: 0;
		}

		@include media($bp-small) {
			@include font-size($font-size-7, $font-leading-7);
		}

		.entry-solutions &,
		.entry-software & {
			max-width: 770px;
			box-sizing: content-box;
			margin-left: auto;
			margin-right: auto;
		}
	}

}