.c-nav {
	@include span-columns(9);
	float: right;
	text-transform: uppercase;
	padding: 0;

	@include media($bp-medium) {
		width: $menu_width;
	}

	ul {
		@extend %reset-list;
		float: right;

		@include media($bp-medium) {
			float: none;
		}

	}

	&__level0 {
		@include transition(all .5s $global-easing);
		float: left;
		padding: 30px 0;
		cursor: pointer;

		@include media($bp-medium) {
			padding: 0;
			float: none;
		}

		.stuck & {
			padding: 10px 0;

			@include media($bp-medium) {
				padding: 0;
			}
		}

		&:hover {
			> a {
				color: palette(green);
				svg {
					fill: palette(green); 
				}

				@include media($bp-medium) {
					color: $c-white;

					svg {
						fill:  $c-white;
					}
				}
			}
			.c-btn {
				color: $c-white;
			}
		}

		&.is-here {
			> a {
				color: palette(green);
			}
			svg {
				fill: palette(green); 
			}
		}
	}

	&__parent {

		&--mini {
			position: relative;
		}

		svg {
			@include transition(all $global-easing-time $global-easing);
			fill: $c-white; 
			width: 10px;
			height: 10px;

			@include media($bp-medium) {
				float: right;
				position: relative;
				top: 8px;
			}
		}
	}

	a {
		color: $c-white;
		display: block;
		padding: 8px 15px;
		position: relative;
		outline: 0;

		@include media($bp-large) {
			padding: 8px 10px;
			font-size: 14px;
		}

		@include media($bp-medium) {
			padding: 8px 15px;
			font-size: 16px;
		}

		&:hover {
			color: palette(green);
			@include media($bp-medium) {
				color: $c-white;
			}
		}
	}

	.c-btn {
		margin-left: 10px;

		@include media($bp-medium) {
			margin-left: 0;
		}

		&:hover {
			color: $c-white;
		}
	}

	&__sub {
		text-align: center;

		@include media($bp-medium) {
			text-align: left;
		}

		li {
			@include dotted-border(0, 100%, repeat-x);
			padding: 0 0 2px 0;
			margin-bottom: 0;

			&:last-child {
				background: none;
				padding-bottom: 0;
			}
			
		}
		a {
			color: palette(blue);
			&:hover {
				@include media($bp-medium) {
					color: palette(blue);
				}
			}
		}
		.is-here {
			> a {
				color: palette(green);
			}
		}
	}

	&__megamenu {
		@include transition(all $global-easing-time $global-easing);
		position: absolute;
		background: $c-white;
		z-index: 10;
		width: 100%;
		left: 0;
		top: 100%;
		padding: 30px;
		text-transform: none;
		border: 1px solid palette(border);
		border-top: 0;
		opacity: 0;
		visibility: hidden;
		top: 105%;

		@include media($bp-medium) {
			@include transition(none $global-easing-time $global-easing);
			opacity: 1;
			visibility: visible;
			top: 0;
			position: relative;
			padding: 0;

			border: 0;
			max-height: 0px;
			overflow: hidden;

			transform: translate3d(0,0,0);
			-webkit-transform: translate3d(0,0,0);
		}

		&.is-active {
			opacity: 1;
			visibility: visible;
			top: 100%;
		}

		h2 {
			font-size: rem(21);

			@include media($bp-medium) {
				display: none;
			}
		}

		p {
			font-size: rem(16);
			line-height: 1.5;
			margin-top: 0;
			margin-bottom: 10px;

			@include media($bp-medium) {
				display: none;
			}
		}
		ul {
			float: none;
		}

		.c-nav__pointer {
			position: absolute;
			top: -10px;
			margin-left: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent $c-white transparent;

			@include media($bp-medium) {
				display: none;
			}
		}

		&--mini {
			width: 100%;
			left: auto;
			padding: 0;

			.c-nav__pointer {
				left: 50% !important;
			}
		}

	}

	.is-active-mobile {
		@include media($bp-medium) {
			height: auto;
			max-height: 9999px;
		}
	}

	&__icon-list {

		li {
			@include media($bp-medium) {
				@include dotted-border(0, 100%, repeat-x);
				padding: 0 0 2px 0;
				margin-bottom: 0;

				&:last-child {
					background: none;
					padding-bottom: 0;
				}

			}

		}
		li a {
			display: block;
			padding: 20px 0 0 60px;
			position: relative;

			@include media($bp-medium) {
				padding: 8px 15px;
			}

			&:hover {
				span {
					color: palette(green);
				}
			}
		}

		img {
			@include transition(all .5s $global-easing);
			position: absolute;
			top: 20px;
			left: 0;

			@include media($bp-medium) {
				display: none;
			}
		}
		

		h3 {
			@include font-size($font-size-5, $font-leading-5);
			font-weight: 400;
			color: $c-black;
			margin: 0;
			@include media($bp-medium) {
				@include font-size($font-size-7, $font-leading-7);
				color: palette(blue);
			}
		}
		p {
			color: $c-font;
			margin: 0 0 3px 0;
			font-size: rem(14);
		}
		a {
			color: palette(blue);
			padding: 0;
		}
		span {
			@include transition(all $global-easing-time $global-easing);
			@include media($bp-medium) {
				display: none;
			}
		}
	}
}

.c-nav-trigger {
	@include transition(all .5s $global-easing);
	color: $c-white;
	text-transform: uppercase;
	float: right;
	padding: 30px 0;
	display: none;
	outline: 0;

	.stuck & {
		padding: 12px 0;

		@include media($bp-xsmall) {
			padding: 4px 0;
		}
	}

	@include media($bp-medium) {
		display: block;
	}

	@include media($bp-xsmall) {
		font-size: 26px;
		padding: 20px 0;

		span {
			display: none;
		}
	}
}