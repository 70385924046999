
.c-checkbox {
  .heading {
    display: none;
  }

  label {
    color: $c-font;
    cursor: pointer;
    font-size: toEms(14px);
    font-weight: normal;
    letter-spacing: 0;
    letter-spacing: 0;
    line-height: 1.6em;
    padding-left: 25px;
    position: relative;
    text-transform: none;
  }

  input {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
  } 
}