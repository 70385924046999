@for $i from 0 through 24 {
    .u-columns-#{$i} { 
        @include columns($i)
    }
}


/**
 * @bp-large
*/

@include breakpoint($bp-large) {
    @for $i from 0 through 24 {
        .u-columns-#{$i}\@large { 
        	@include columns($i)
    	}

    }
}

/**
 * @bp-medium
*/

@include breakpoint($bp-medium) {
    @for $i from 0 through 24 {
        .u-columns-#{$i}\@medium { 
        	@include columns($i)
    	}
    }
}

/**
 * @bp-small
*/

@include breakpoint($bp-small) {
    @for $i from 0 through 24 {
        .u-columns-#{$i}\@small { 
        	@include columns($i)
    	}
    }
}

/**
 * @bp-xsmall
*/

@include breakpoint($bp-xsmall) {
    @for $i from 0 through 24 {
        .u-columns-#{$i}\@xsmall { 
        	@include columns($i)
    	}
    }
}