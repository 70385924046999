.c-post {
	text-align: left;
	margin-bottom: 40px;

	&__img {
		width: 100%;
	}

	&__cat {
		//color: palette(red);
		display: block;
		margin-top: 10px;
	}

	&__title {
		color: $c-black;
		font-weight: 400;
		font-size: rem(21);
		line-height: 1.3;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	p {
		margin: 10px 0;
	}
	.c-author {
		padding-top: 20px;
	}

}