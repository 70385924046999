.o-section {
	@include row;
	display: block;
	padding: $spacing-large 0;

	&--centered {
		text-align: center;
	}

	&--bg {
		background: palette(grey, light);
	}

	&--reverse {
		background: palette(blue);
		color: $c-white;
		h2, h3, h4, h5 {
			color: $c-white;
		}
	}

	&--reverse-alt {
		background: palette(green);
		color: $c-white;
		h2, h3, h4, h5 {
			color: $c-white;
		}
	}

	&--border-bottom {
		border-bottom: 5px solid palette(blue);
	}

	&--border-dotted {
		padding-bottom: 0;
		.o-grid {
			@include dotted-border(0, 100%, repeat-x) 
			padding-bottom: $spacing-large;
		}
	}

	&--nopad {
		padding: 0;
	}

	&--nopad-bottom {
		padding-bottom: 0;
	}

	&--grey {
		background: palette(grey, light);
	}

	&--blue {
		background: palette(blue);
		color: $c-white;
		a {
			color: palette(green);
		}
	}

	&--green {
		background: palette(green);
		color: $c-white;
	}

}

.o-section--white + .o-section--white {
	padding-top: 0;
}
