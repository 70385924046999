.c-pill-list {
	text-align: center;
	padding: 0 0 30px 0;
	li {
		display: inline;
	}
	a {
		display: inline-block;
		background: palette(grey, light);
		padding: 5px 15px;
		border: 1px solid palette(border);
		margin: 5px 2px;
	}

	.is-here a {
		background: palette(blue);
		border: 1px solid palette(blue);
		color: $c-white;
	}

	&--detail {
		padding-bottom: 0;
	}
}