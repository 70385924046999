.u-align-left.u-align-left {
	text-align: left;
}

.u-align-center.u-align-center {
	text-align: center;
}

.u-align-right.u-align-right {
	text-align: right;
}

.u-uppercase.u-uppercase {
	text-transform: uppercase;
}

.u-lowercase.u-lowercase {
	text-transform: lowercase;
}

.u-no-case.u-no-case {
	text-transform: none;
}

.u-color-black.u-color-black {
	color: black;
}
