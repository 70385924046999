.c-footer {
	background: palette(grey, dark);
	color: $c-white;
	padding: 40px 0 0 0;


	a {
		color: $c-white;
		&:hover {
			color: palette(green);
		}
	}

	h2 {
		color: palette(blue);
		font-weight: 400;
		font-size: rem(18);
	}

	&__extra {
		background: palette(grey, x-dark);
		padding: 20px;
		text-align: center;
		margin-top: 40px;
		p {
			margin: 0;
			font-size: rem(13);
		}
	}
}

.c-footer--mini {
	padding: 25px 0 0 0;

	.c-footer__extra {
		margin-top: 10px;
	}

}