.c-infographic {
	@include clearfix;
	margin-top: 40px;
	text-align: center;

	&__top {
		@include clearfix;
		padding-bottom: 60px;
		margin-bottom: 20px;
		background: url(../images/infographic-arrow.svg) bottom center no-repeat;
		background-size: 100%;

		@include media($bp-small) {
			background: none;
			padding-bottom: 0px;
			margin-bottom: 0px;
		}

		&--no-bottom {
			background-image: none;
			padding-bottom: 50px;
			margin-bottom: 0;
		}
	}

	&__item {
		@include media($bp-small) {
			margin-bottom: 30px;
		}
	}

	&__number {
		display: block;
		margin: 0 auto;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		background: palette(blue);
		color: white;
		font-size: 17px;
		font-weight: bold;
		padding-top: 6px;
		margin-top: -70px;
		margin-bottom: 30px;

		@include media($bp-small) {
			display: none;
		}
	}

	h3 {
		@include font-size($font-size-5, $font-leading-5);
		font-weight: 400;
		color: $c-black;
		margin: 20px 0 0 0;
	}

	img {
		@include transition(all .5s $global-easing);
		@include transform(scale(0));
		opacity: 0;

		-webkit-backface-visibility: hidden;

		@include media($bp-xsmall) {
			width: 80px;
			height: 80px;
		}
	}

	.is-visible img {
		@include transform(scale(1));
		opacity: 1;
	}
}