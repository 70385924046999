@for $i from -24 through 24 {
    .u-grid-#{$i} { 
        @include span-columns($i);
    }
    .u-prefix-#{$i} { 
        @include shift($i);
    }
}

/**
 * @bp-large
*/

@include breakpoint($bp-large) {
    @for $i from -24 through 24 {
        .u-grid-#{$i}\@large { 
            @include span-columns($i);
        }
        .u-prefix-#{$i}\@large { 
            @include shift($i);
        }

    }
}

/**
 * @bp-medium
*/

@include breakpoint($bp-medium) {
    @for $i from -24 through 24 {
        .u-grid-#{$i}\@medium { 
            @include span-columns($i);
        }
        .u-prefix-#{$i}\@medium { 
            @include shift($i);
        }
    }
}

/**
 * @bp-small
*/

@include breakpoint($bp-small) {
    @for $i from -24 through 24 {
        .u-grid-#{$i}\@small { 
            @include span-columns($i);
        }
        .u-prefix-#{$i}\@small { 
            @include shift($i);
        }
    }
}

/**
 * @bp-xsmall
*/

@include breakpoint($bp-xsmall) {
    @for $i from -24 through 24 {
        .u-grid-#{$i}\@xsmall { 
            @include span-columns($i);
        }
        .u-prefix-#{$i}\@xsmall { 
            @include shift($i);
        }
    }
}

.u-grid-last {
    @include omega;
}

@include breakpoint($bp-medium) {
    .u-grid-last\@medium {
        @include omega;
    }
}
