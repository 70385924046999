.c-contact {
	margin-top: 20px;
	@include media($bp-small) {
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	a {
		display: block;
		color: $c-white;
	}
	h3 {
		@include font-size($font-size-4, $font-leading-4);
		color: $c-white;
		font-weight: 400;
		position: relative;
		padding-bottom: 12px;
		margin-bottom: 10px;

		@include media($bp-medium) {
			@include font-size($font-size-5, $font-leading-5);
		}

		&:after {
			content: "";
			width: 20%;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 40%;
			background: $c-black;
		}
	}
	p {
		margin: 0;
		padding: 0 30px;

		@include media($bp-medium) {
			padding: 0;
		}
	}
	.tel {
		display: block;
	}
	svg {
		fill: $c-white;
		height: 50px;
		max-width: 55px;
	}
}