.o-grid,
%o-grid {
    @include outer-container;
    position: relative;
    padding: 0 $spacing-small;

    @include breakpoint($bp-medium) {
        padding: 0 $spacing-medium;
    }

    &--no-pad {
        padding: 0;
    }
}
