.c-author {
	display: table;
	//width: 100%;
	margin: 20px 0;
	
	&__img,
	&__details {
		display: table-cell;
		vertical-align: middle;
	}

	&__img {
		width: 25%;
		max-width: 90px;
		min-width: 90px;
	}

	&__details {
		width: 75%;
		padding-left: 10px;
		line-height: 1.3;
	}

	&__person {
		display: block;
		color: $c-black;
		font-weight: 600;
	}
	&__date {
		display: block;
		font-size: rem(13);

		svg {
			width: 15px;
			height: 15px;
			fill: palette(blue);
			position: relative;
			top: 2px;
		}
	}
	&__role {
		display: block;
		font-size: rem(13);
		line-height: 1;
	}

	&--centered {
		text-align: center;
		margin: 0 auto;
		margin-bottom: 20px;

		.c-author__img,
		.c-author__details {
			display: block;
			width: 100%;
			margin: 0 auto;
		}
	}
}