.c-layer {
	@include clearfix;
	display: table;
	width: 100%; 
	
	&__media {
		width: 60%;
		padding: 0 30px 0 0;
		display: table-cell;
		vertical-align: middle;
		text-align: center;

		@include media($bp-small) {
			display: block;
			margin-bottom: 20px;
			padding: 0;
			width: 100%;
			
		}

		img {
			max-width: 720px;
			width: 100%;
		}

		&--portrait {
			img {
				max-width: 480px;
				width: 100%;
			}
		}
	}
	
	&__txt {
		width: 40%;
		display: table-cell;
		vertical-align: middle;
		text-align: left;
		direction: ltr;

		@include media($bp-small) {
			display: block;
			padding: 0;
			width: 100%;
			text-align: center;
		}

		ul {
			list-style: disc;
			list-style-position: inside;
			padding-left: 18px;
			margin: 1em 0;

			@include media($bp-small) {
				list-style-position: inside;
			}
		}
	}

	&--rtl {
		direction: rtl;

		.c-layer__media {
			padding: 0 0 0 30px;

			@include media($bp-small) {
				padding: 0;
			}
		}
	}

	&--ltrAlt {
		.c-layer__media {
			width: 33%;
	
			@include media($bp-small) {
				display: block;
				margin-bottom: 20px;
				padding: 0;
				width: 100%;
				text-align: center;
			}
		}
		.c-layer__txt {
			width: 66%;

			@include media($bp-small) {
				display: block;
				padding: 0;
				width: 100%;
				text-align: center;
			}
		}
	}

	&--rtlAlt {
		direction: rtl;
		
		.c-layer__media {
			width: 33%;

			@include media($bp-small) {
				display: block;
				margin-bottom: 20px;
				padding: 0;
				width: 100%;
				text-align: center;
			}
		}

		.c-layer__txt {
			width: 66%;

			@include media($bp-small) {
				display: block;
				padding: 0;
				width: 100%;
				text-align: center;
			}
		}
	}

}