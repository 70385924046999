.s-cms-content {
	@include clearfix;

	// Headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {}

	a {
		border-bottom: 1px solid;
		color: $c-link;
		text-decoration: none;

		&:hover {
			color: $c-link-hover;
			border-bottom-color: transparent;
		}

		&:visited,
		&:active {
			color: $c-link-visited;
			border-bottom: 1px solid;
		}
	}

	// Images
	figure {
		margin-bottom: $spacing-medium;
	}

	// Image Captions
	figcaption {
		background: #ccc;
		padding: $spacing-small $spacing-base;
	}

	blockquote {

	}

	cite {

	}

	a {
		word-break: break-word;
	}

	p {
		margin-bottom: $spacing-medium;
		margin-top: 0;

		&:last-of-type:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		@extend %c-list;

		li:before {
			content: ".";
			font-size: toRems(35px);
			line-height: 10px !important;
			top: -0.07em !important;
		}
	}

	ol {
		@extend %c-list;
		counter-reset: section;

		li:before {
			content: counter(section,decimal)".";
			counter-increment: section;
			font-weight: $font-weight-semibold;
			left: 0;
			top: 0.275em !important;
		}

		ol {
			li {
				&:before {
					content: counter(section);
				}
			}
		}
	}
}
