.o-section--video {
	background-color: #F7F7F7;
	padding-top: 80px;
	padding-bottom: 80px;
}

.c-video {
	display: flex;

	@include media($bp-xsmall) {
		flex-wrap: wrap;
	}

	&__image {
		flex: 0 0 50%;
		align-self: flex-start;
		position: relative;

		@include media($bp-xsmall) {
			flex-basis: 100%;
		}

		img {
			display: block;
		}

		&-overlay {
			position: absolute;
			background-color: rgba(0,0,0,.6);
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
			}

			&:before {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background-color: rgba(palette(blue, base), .5);
				transform: translate(-50%, -50%);
			}

			&:after {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 23.5px 0 23.5px 41px;
				border-color: transparent transparent transparent #ffffff;
				transform: translate(-50%, -50%) translateX(14%);
			}
		}
	}

	&__content {
		flex: 0 0 50%;
		padding-left: 80px;
		align-self: center;

		@include media($bp-small) {
			padding-left: 40px;
		}

		@include media($bp-xsmall) {
			flex-basis: 100%;
			padding-left: 0;
			padding-top: 20px;
		}

		h3 {
			font-weight: 400;
		}

		.c-btn {
			margin-top: 10px;
		}
	}

	&__blog {
		margin: 1em 0;

		@include media($bp-medium) {
			margin-left: -30px;
			margin-right: -30px;
		}
	}
}

