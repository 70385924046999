.c-article {
	@include dotted-border(0, 100%, repeat-x); 
	@include clearfix;
	padding-bottom: 26px;
	margin-bottom: 30px;
	text-align: left;

	&:first-of-type {
		margin-top: 30px;
	}

	&:last-of-type {
		background: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&__media {
		@include span-columns(3);

		@include media($bp-small) {
			@include span-columns(12);
		}
	}
	
	&__txt {
		@include span-columns(9);

		@include media($bp-small) {
			@include span-columns(12);
		}
	}

	h3 {
		font-weight: 400;
	}
}
