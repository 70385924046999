h1 {
	@include font-size($font-size-1, $font-leading-1);
	color: $c-black;
	margin-bottom: 0;

	@include media($bp-medium) {
		@include font-size($font-size-2, $font-leading-2);
	}

	@include media($bp-small) {
		@include font-size($font-size-3, $font-leading-3);
	}

	@include media($bp-xsmall) {
		@include font-size($font-size-4, $font-leading-4);
	}

	// + p {
	// 	@include font-size($font-size-5, $font-leading-5);

	// 	@include media($bp-small) {
	// 		@include font-size($font-size-6, $font-leading-6);
	// 	}
	// }
}

h2 {
	@include font-size($font-size-2, $font-leading-2);
	color: $c-black;
	margin-bottom: 0;

	@include media($bp-small) {
		@include font-size($font-size-3, $font-leading-3);
	}

	@include media($bp-xsmall) {
		@include font-size($font-size-4, $font-leading-4);
	}

	// + p {
	// 	@include font-size($font-size-5, $font-leading-5);

	// 	@include media($bp-small) {
	// 		@include font-size($font-size-6, $font-leading-6);
	// 	}
	// }
}

h3 {
	@include font-size($font-size-3, $font-leading-3);
	color: $c-black;
	margin-bottom: 0;

	@include media($bp-small) {
		@include font-size($font-size-4, $font-leading-4);
	}

	@include media($bp-xsmall) {
		@include font-size($font-size-5, $font-leading-5);
	}
}

h3 {
	color: $c-black;
	margin-bottom: 0;
}

h4 {
	color: $c-black;
	margin-bottom: 0;
}

h5 {
	color: $c-black;
	margin-bottom: 0;
}